import React from "react";
import { render } from "react-dom";
import { GlobalStyles, ThemeProvider, THEMES } from "@justomx/avocado-ui";

import { ApolloProvider } from "@/graphql/ApolloProvider";
import { Footer } from "./Footer";
import { getCmsFooter } from "../Layout/LayoutMain";

const container = document.getElementById("footer");

async function fetchCmsLogos() {
  const cmsFooter = await getCmsFooter();

  const { logo: logoFooter } = cmsFooter;

  if (container) {
    render(
      <ApolloProvider>
        <ThemeProvider theme={THEMES.light}>
          <GlobalStyles />
          <Footer logo={logoFooter} />
        </ThemeProvider>
      </ApolloProvider>,
      container
    );
  }
}

fetchCmsLogos();
