import React from "react";
import { ApolloProvider as Provider } from "@apollo/client";
import PropTypes from "prop-types";
import { coreClient } from "@/graphql/clients";

function ApolloProvider({ children, ...props }) {
  return (
    <Provider client={coreClient} {...props}>
      {children}
    </Provider>
  );
}

ApolloProvider.propTypes = {
  children: PropTypes.node,
};

export { ApolloProvider };
